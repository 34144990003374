class Performance {

    static get performanceEnd() {
        return 'performanceEnd';
    }

    static get awaitEvent() {
        return 'performanceAwaitEvent';
    }

    constructor() {
        this.topic       = 'Test default';
        this.testCount   = 0;
        this.testDelay   = 10;
        // noinspection JSDeprecatedSymbols
        this.startTime   = performance.timing.requestStart;
        this.endTime     = 0;
        this.endKey      = 'domComplete';
        this.page        = location.origin + location.pathname;
        const res        = /#performance=(\d+)?,(\d+)?/.exec(location.hash);
        this.testResults = this.getTestResults();
        if (res) {
            this.testCount = parseInt(res[1]);
            this.testDelay = parseInt(res[2]);
            if (!this.testCount) {
                this.finishTest();
            }
        }
        if (this.testCount && this.testDelay) {
            this.setTestTimeout();
        }
        this.bindEvents();
    }

    setTestTimeout() {
        this.timer = setTimeout(() => {
            this.testCount--;
            // noinspection JSDeprecatedSymbols
            this.endTime = this.endTime || performance.timing.domComplete;
            this.pushToResults({
                time: this.endTime - this.startTime,
                key:  this.endKey,
            })
            History.pushState(null, null, '#performance=' + this.testCount + ',' + this.testDelay);
            location.reload();
        }, this.testDelay * 1000);
    }

    pushToResults(data) {
        this.testResults.tests.push(data);
        localStorage[this.getPageKey()] = JSON.stringify(this.testResults);
    }

    runPageTest(count, delay) {
        history.pushState(null, null, '#performance=' + count + ',' + delay);
        location.reload();
    }

    bindEvents() {
        $(document).on(Performance.performanceEnd, (e, endKey) => {
            this.endKey  = endKey;
            this.endTime = e.timeStamp;
            if (this.testCount) {
                this.setTestTimeout();
            }
        }).on(Performance.awaitEvent, () => {
            clearTimeout(this.timer);
        })
    }

    getTestResults() {
        let key = this.getPageKey();
        if (!localStorage[key]) {
            let results       = {
                topic:    this.topic,
                testPage: this.page,
                count:    this.testCount,
                delay:    this.testDelay,
                tests:    [],
            };
            localStorage[key] = JSON.stringify(results);
            return results;
        }
        return JSON.parse(localStorage[key]);
    }

    getPageKey() {
        return this.page.replace(/[\/:\.]/g, '');
    }

    finishTest() {
        let duration = this.testResults.tests.reduce((e, i) => {return i ? i.time + e : e}, 0) /
            this.testResults.tests.length;
        console.info(
            this.testResults.topic + ' ' + this.page +
            '\ncount: ' + this.testResults.tests.length +
            '\nduration: ' + duration
        );
        console.table(this.testResults.tests);
        history.pushState(null, null, ' ');
        delete localStorage[this.getPageKey()];
    }
}

let PerformanceTest;
$(document).ready(() => {
    PerformanceTest = new Performance();
})
